export const contractionsList = [
  {
    formal: ["État des lieux", "États des lieux"],
    cont: "EDL",
    invariable: true
  },
  {
    formal: ["Référence"],
    cont: "Réf"
  },
  {
    formal: ["Rendez-vous"],
    cont: "Rdv"
  },
  {
    formal: ["Rez de chaussée"],
    cont: "RDC"
  },
  {
    formal: ["Surface"],
    cont: "Surf"
  },
  {
    formal: ["Type"],
    cont: "T"
  },
]

export const messages = {
  accommodation: {
    access: "Accès",
    accessCodes: "Aucun code d'accès renseigné | Codes d'accès : {message}",
    DoorNumber: "Pas de numéro de porte renseigné | Numéro de porte : {message}",
    checkingsNumber: "Aucun état des lieux | 1 état des lieux | {n} états des lieux",
    surface: "Surface : {n} m²",
    type: "Type {n}",
    isFurnished: "Meublé",
    isNotFurnished: "Non meublé",
    reference: "Référence : ",
    Summary: {
      goTo: "Voir"
    },
    View: {
      displayButton: {
        show: "Voir",
        hide: "Cacher"
      }
    }
  },
  appointment: {
    default: (date) => { return `Rendez-vous le ${date}` },
    tomorrow: (time) => { return `Rendez-vous demain à ${time}` },
    today: (time) => { return `Rendez-vous aujourd'hui à ${time}` },
    past: (date) => { return `Rendez-vous du ${date}` },
    noAppointment: "Pas de rendez-vous prévu",
  },
  checking: {
    check: "État des lieux",
    in: "Entrant",
    out: "Sortant",
    checkIn: "@:checking.check @.lower:checking.in",
    checkOut: "@:checking.check @.lower:checking.out",
    noAppointment: "Rendez-vous non planifié",
    occupantsCount: "Libre | 1 locataire | {n} locataires",
    occupant: "Locataire | Locataires",
    owner: "Propriétaire | Propriétaires",
    expert: "Expert",
    representative: "Madataire | Madataires",
    roomsCount: "Non renseigné | 1 pièce | {n} pièces",
    comment: "Commentaire",
    state: {
      toDo: "À réaliser",
      inProgress: "En cours de réalisation",
      done: "Réalisé et signé",
      canceled: "Dossier annulé",
    },
    status: {
      free: "License gratuite",
      expert: "License Expert",
    },
    annexes: {
      empty: "Aucune annexe",
      title: "Annexes",
      attic: "Grenier | 1 grenier | {n} greniers",
      box: "Box | 1 box | {n} box",
      cellar: "Cave | 1 cave | {n} caves",
      garage: "Garage | 1 garage | {n} garages",
      indoorParking: "Parking intérieur | 1 parking intérieur | {n} parkings intérieurs",
      outdoorParking: "Parking extérieur | 1 parking extérieur | {n} parkings extérieurs",
      storeRoom: "Débarra | 1 débarra | {n} débarras",
    },
  },
  common: {
    dropdown: {
      toogle: {
        desc: 'Faire défiler'
      }
    },
    emptyField: "Non renseigné",
    form: {
      email: "Adresse mail",
      password: "Mot de passe",
      newPassword: "Nouveau mot de passe",
      oldPassword: "Votre mot de passe actuel",
      passwordConfirm: "Confirmez le mot de passe",
      passwordConfirmFailed: "Les mots de passe ne sont pas identiques.",
      passwordRequirements: "Doit contenir au moins :",
      passwordRequirementsList: {
        number: "10 charactères.",
        letter: "1 lettre.",
        majLetter: "1 lettre Majuscule.",
        didgit: "1 chiffre.",
        special: "1 charactère spécial."
      },
      newPerson: "Nouveau profil",
      cancel: "Annuler",
      select: {
        title: "Sélectionner :",
        optionsSelectedLabel: 'sélections',
        noResultsText: 'Aucun résultat',
        searchPlaceholder: "Rechercher...",
        selectAllLabel: "Sélectionner tous"
      },
      submitButton: "Soumettre",
      validate: "Valider",
      confirm: 'Confirmer',
      clear: 'Effacer',
      clearAll: 'Tout effacer',
      modify: "Modifier",
      datePicker: {
        displayedFormat: "JJ/MM/AAAA", // date format usually used
        dateFormat: "DD/MM/YYYY", // In english: date format usually used
        okBtnLabel: 'Confirmer la sélection',
        clearBtnLabel: 'Effacer la sélection',
        cancelBtnLabel: 'Annuler la sélection',
        nextMonthLabel: 'Prochains mois',
        prevMonthLabel: 'Mois précédent',
        nextYearLabel: 'Prochaine année',
        prevYearLabel: 'Année précédente',
        nextMultiYearLabel: '24 prochaines années',
        prevMultiYearLabel: '24 années précédentes',
        switchToMultiYearViewLabel: 'Choisir le mois et l\'année',
        switchToDayViewLabel: 'Choisir le jour',
        monthsFull: "Janvier, Février, Mars, Avril, Mai, Juin, Juillet, Août, Septembre, Octobre, Novembre, Decembre",
        monthsShort: "Jan, Fév, Mar, Avr, Mai, Juin, Juil, Août, Sep, Oct, Nov, Déc",
        weekdaysFull: "Dimanche, Lundi, Mardi, Mercredi, Jeudi, Vendredi, Samedi",
        weekdaysShort: "Dim, Lun, Mar, Mer, Jeu, Ven, Sam",
        weekdaysNarrow: "D, L, M, M, J, V, S",
      },
      timePicker: {
        hoursFormat: "24", // 12 or 24
        hoursPlaceholder: "HH:MM", // Omit AM/PM in 24 hours format
        amLabel: "Matin",
        pmLabel: "Après-midi",
        invalidLabel: "Format non valide",
      },
    }
  },
  displayError: {
    clear: "Effacer",
    default: "Une erreur est survenue.",
    auth_login_401: "Échec de la connexion, l'e-mail ou le mot de passe est incorrect.",
    500: "Erreur serveur ou problème réseau, veuillez réessayer plus tard.",
  },
  unknow: "Non renseigné",
  license: {
    validUntil: (date) => { return `Valide jusqu'au ${date}` },
    noDate: "Pas de date limite",
    subLicense: "Sous-license"
  },
  link: {
    goTo: "Aller à",
    see: "Voir",
  },
  router: {
    Accueil: "Déconnexion",
    Home: "Menu principal",
    Accommodation: "Lot",
    AccommodationPost: "Ajouter un lot",
    AccommodationUpdate: "Modifier le lot",
    AccommodationsList: "Lots",
    Checking: "État des lieux",
    CheckingPost: "Préparer un état des lieux",
    CheckingUpdate: "Modifier un rendez-vous d'état des lieux",
    CheckingsList: "États des lieux",
    License: "License",
    LicensesList: "Licenses",
    Profil: "Profil",
    SignIn: "Inscription",
    ProfilUpdate: "Modifier mon profil",
    ChangePassword: "Changer de mot de passe",
    ForgotPassword: "Réinitialiser mon mot de passe",
  },
  specific: {
    floor: ({ named }) => {
      let number = named("number");
      let ordinal = messages.specific.ordinal(number);
      if (number == 0) {
        return "Rez de chaussée"
      }
      else if (number > 0) {
        return ordinal += " étage"
      }
      else {
        return ordinal.slice(1) + " sous-sol" // without the "-"
      }
    },
    ordinal: (n) => {
      return Math.abs(n) === 1 ? n += "<sup>er</sup>" : n += "<sup>e</sup>"
    },
  },
  // Views/Components
  AccommodationForm: {
    accommodation: {
      access: "Commentaire",
      buildingYear: "Année de construction",
      doorNumber: "Numéro de porte",
      floor: "Étage",
      garden: "Jardin / Extérieurs",
      isFurnished: "Meublé",
      reference: "Référence",
      surface: "Surface",
      type: "Type"
    },
    title: {
      access: "Accès",
      accommodationInfos: "Lot",
      new: "Nouveau lot",
      update: "Modifier le lot"
    }
  },
  Accueil: {
    welcome: {
      welcome: "Bienvenue !",
      text: "Connectez-vous pour utiliser l'application."
    },
    subscribe: {
      text: "Pas encore inscrit ?",
      link: {
        text: "C'est par ici !"
      }
    },
    knowUs: {
      text: "Vous ne connaissez pas eecheck ?",
      link: {
        text: "Découvrir",
        desc: "Notre site vitrine"
      }
    },
    forgotPassword: "Mot de passe oublié ?",
    submit: "Me connecter",
  },
  Brand: {
    desc: "Logo eecheck"
  },
  CheckingView: {
    appointment: "Rendez-vous",
    changeLicenseButton: {
      toPro: "Passer en Expert",
      change: "Utiliser une autre license"
    },
    modifyButton: "Modifier l'état des lieux",
    roomsCountTitle: "Nombre de pièces à checker"
  },
  CheckingForm: {
    annexe: {
      explanations: "(Vous pouvez préciser le numéro)"
    },
    appointment: {
      title: "Rendez-vous",
      date: "Sélectionner une date",
      time: "Sélectionner une heure",
    },
    checking: {
      count: "Nombre",
      explanations: "Nombre total de pièces à checker (inclut les entrées, couloirs, WC...).",
      exteriors: "N'oubliez pas les espaces extérieurs privés (jardins, terrasses...).",
    },
    license: "Sélectionnez une license :",
    newAddress: "Nouveau lot",
    selectAccommodation: "Sélectionnez un lot :",
    orCreate: "Ou :",
    select: {
      accommodation: "Lot",
      occupant: "Locataire",
      owner: "Propriétaire",
      expert: "Expert",
      representative: "Représentant"
    },
    title: {
      addAnnexes: "Ajouter des annexes",
      checkingInfos: "Infos",
      new: "Nouvel état des lieux",
      roomsCount: "Nombre de pièces",
      update: "Modifier l'état des lieux",
      occupants: "Locataires",
      owners: "Propriétaires",
      experts: "Experts",
      representatives: "Mandataires"
    }
  },
  ForgotPassword: {
    title: "Mot de passe oublié",
  },
  ForgotPasswordComplete: {
    checkMail: "Veuillez vérifier votre boîte mail.",
    confirmMail: "Vous allez recevoir un email dans quelques minutes pour réinitialiser votre mot de passe."
  },
  GradientContainer: {
    seeLess: "Moins d'infos",
    seeMore: "Plus d'infos",
  },
  LangSwitcher: {
    button: {
      text: "Langue",
      desc: "Changer la langue"
    },
    dropdown: {
      "en-EN": "Anglais",
      "fr-FR": "Français",
      "ja-JA": "Japonais"
    }
  },
  LicensesList: {
    title: "Vos licenses",
    personal: {
      badge: "(personnelle)",
    },
    group: {
      subs: "Pas de sous-license | 1 sous-license | {n} sous-licenses",
      grantSub: "Accorder une sous-license"
    },
    owner: "Au nom de :",
    credits: "Vous n'avez plus de checking expert | 1 checking expert disponible | {n} checkings experts disponibles",
    addCredits: "Acheter des crédits",
    upgrade: "Prendre un abonnement",
  },
  LicenseSwitcher: {
    button: {
      text: "License",
      desc: "Changer de license"
    },
  },
  ListAppearanceSwitcher: {
    card: "Afficher sous forme de cartes",
    list: "Afficher comme une liste"
  },
  Paiement: {
    addCredits: "Acheter des checkings experts :",
    title: "Votre commande",
    total: "Total :",
    One: {
      purchasesOptions: "( 7€/checking, 30€/5 checkings )"
    }
  },
  Profil: {
    title: "Votre profil",
    name: "Nom",
    firstName: "Prénom",
    mailAddress: "Email",
    phone: "Téléphone",
    cellPhoneNumber: "Portable",
    phoneNumber: "Fixe",
    faxNumber: "Fax",
    address: "Adresse",
    zipCode: "Code postal",
    city: "Ville",
    state: "",
    country: "Pays",
    codes: "Codes d'accès ",
    licenses: "1 license | {n} licenses disponibles",
    licensesButton: "Voir",
    personNatural: "Physique",
    personLegal: "Morale",
    identificationNumber: "Numéro",
    identificationNumberType: "Identification",
    img: {
      desc: "Votre photo de profil"
    }
  },
  ProfilDropdown: {
    toogleTitle: "Voir le menu utilisateur"
  },
  ProfilInfos: {
    alt: "Image de profil"
  },
  ProfilUpdate: {
    civility: "Civilité :",
    civilityLabel: {
      M: "M.",
      Mme: "Mme"
    },
    title: {
      user: "Utilisateur",
      phones: "Téléphones",
      address: "Adresse",
      image: "Photo de profil",
      personType: "Personne"
    }
  },
  Register: {
    choosePassword: "Choisissez un mot de passe",
    goSignIn: "Veuillez vous réinscrire",
    invalidToken: "Désolé, votre lien de validation est expiré.",
    submit: "Valider l'inscription",
    title: "Confirmation d'inscription"
  },
  RegisterComplete: {
    thankYou: "Merci de votre confiance !",
    checkMail: "Veuillez vérifier votre boîte mail.",
    confirmMail: "Vous allez recevoir un email de confirmation dans quelques minutes pour finaliser votre inscription."
  },
  ResetPassword: {
    invalidToken: "Désolé, ce lien est expiré",
    title: "Réinitialisation de mot de passe",
  },
  Search: {
    clear: "Effacer",
    find: "Chercher",
    placeholder: "Rechercher"
  },
  SideNavigation: {
    hide: "Masquer",
    show: "Afficher"
  },
  SignIn: {
    email: "Votre mail",
    language: "Langue",
    submit: "Valider",
    theme: "Apparence du site",
    title: "Inscription"
  },
  ThemeSwitcher: {
    button: {
      text: "Apparence",
      desc: "Mode nuit/jour"
    },
    dropdown: {
      dark: "Sombre",
      system: "Par défaut",
      light: "Clair"
    }
  },
}